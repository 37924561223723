/*Tips for CSS:
    - For 100% height / width need to account for any margins i.e. -'ves
    -
*/
@font-face {
	/*Gothic Book Font */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/GothamLightRegular.otf");
	font-weight: 100;
	font-style: normal;
	font-stretch: expanded;
}
@font-face {
	/*Gothic Book Font */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-Book.otf");
	font-weight: 200;
	font-style: normal;
	font-stretch: normal;
}
@font-face {
	/*Gothic Book Font - Italic*/
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-BookIt.otf");
	font-weight: 200;
	font-style: italic;
	font-stretch: normal;
}
@font-face {
	/*Gothic Book Condensed Font */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-BkCd.otf");
	font-weight: 200;
	font-style: normal;
	font-stretch: condensed;
}
@font-face {
	/*Gothic Book Condensed Font - Italic */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-BkCdIt.otf");
	font-weight: 200;
	font-style: italic;
	font-stretch: condensed;
}
@font-face {
	/*Gothic Book Compressed Font */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-BkCp.otf");
	font-weight: 200;
	font-style: normal;
	font-stretch: extra-condensed;
}
@font-face {
	/*Gothic Book Compressed Font - Italic*/
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-BkCpIt.otf");
	font-weight: 200;
	font-style: italic;
	font-stretch: extra-condensed;
}
@font-face {
	/*Gothic Book Extra Compressed Font*/
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-BkXCp.otf");
	font-weight: 500;
	font-style: normal;
	font-stretch: ultra-condensed;
}
@font-face {
	/*Gothic Demi */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-Demi.otf");
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}
@font-face {
	/*Gothic Demi */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-DemiIt.otf");
	font-weight: 500;
	font-style: italic;
	font-stretch: normal;
}
@font-face {
	/*Gothic Demi Condensed Font*/
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-DmCd.otf");
	font-weight: 500;
	font-style: normal;
	font-stretch: condensed;
}
@font-face {
	/*Gothic Demi Condensed Font Italic*/
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-DmCdIt.otf");
	font-weight: 500;
	font-style: italic;
	font-stretch: condensed;
}
@font-face {
	/*Gothic Demi Compressed Font */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-DmCp.otf");
	font-weight: 900;
	font-style: normal;
	font-stretch: extra-condensed;
}
@font-face {
	/*Gothic Demi Compressed Font Italic */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-DmCpIt.otf");
	font-weight: 900;
	font-style: italic;
	font-stretch: extra-condensed;
}
@font-face {
	/*Gothic Demi Extra Compressed Font */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-DmXCp.otf");
	font-weight: 500;
	font-style: italic;
	font-stretch: ultra-condensed;
}
@font-face {
	/*Gothic Medium Condensed Font */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-MdCd.otf");
	font-weight: 700;
	font-style: normal;
	font-stretch: condensed;
}
@font-face {
	/*Gothic Medium Condensed Italic Font */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-MdCdIt.otf");
	font-weight: 700;
	font-style: italic;
	font-stretch: condensed;
}
@font-face {
	/*Gothic Medium Font*/
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-Med.otf");
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
}
@font-face {
	/*Gothic Medium Italic Font */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-MedIt.otf");
	font-weight: 700;
	font-style: italic;
	font-stretch: normal;
}
@font-face {
	/*Gothic Heavy Font*/
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-Hvy.otf");
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
}
@font-face {
	/*Gothic heavy Italic Font */
	font-family: "ITC Franklin Gothic Std";
	src: url("./Resources/Fonts/ITCFranklinGothicOfficial/ITCFranklinGothic/ITCFranklinGothicStd-HvyIt.otf");
	font-weight: 900;
	font-style: italic;
	font-stretch: normal;
}

#LoadingBox.loading {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 200vh;
	background-color: white;
	opacity: 1;
	transition: all 1000ms linear 0;
}

/* #LoadingBox.loaded {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: 0;
  transition: all 1000ms linear 0;
} */

* {
	/* These conditions set the defualt font settings for all items*/
	font-family: "ITC Franklin Gothic Std";
	color: black;
	opacity: 1;
	margin: 0;
	padding: 0;
	letter-spacing: 4px;
	font-weight: normal;
	font-stretch: extra-condensed;
	font-size: 19px;
	/* border: 1px solid red; */
}

@media only screen and (min-width: 2200px) {
	* {
		/* These media queries shrink the page font size as the screen size goes down */
		font-size: 27px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	/* These media queries shrink the page font size as the screen size goes down */
	* {
		font-size: 16px;
	}
}

@media only screen and (max-width: 1200px) {
	* {
		font-size: 15px;
	}
}
/* @media only screen and (min-width: 1200px) and (max-width: 1399px) {
	* {
		font-size: 18px;
	}
}
@media only screen and (min-width: 1000px) and (max-width: 1199px) {
	* {
		font-size: 16px;
	}
}
@media only screen and (min-width: 700px) and (max-width: 999px) {
	* {
		font-size: 14px;
	}
}
@media only screen and (max-width: 699px) {
	* {
		font-size: 12px;
	}
} */

/* br {
	display: block;
	line-height: 30px;
} */

a {
	text-decoration: none;
	cursor: pointer;
}

p,
strong,
em {
	font-size: 1.3rem;
	letter-spacing: -0.5px;
	font-weight: 200;
	font-stretch: extra-condensed;
	line-height: 150%;
	text-align: justify;
}

h1 {
	font-size: 1.35rem;
	letter-spacing: 2px;
	color: #505962;
	font-weight: 900;
}

h2 {
	font-size: 1.1rem;
	font-weight: 900;
	font-stretch: extra-condensed;
	text-align: left;
	color: #505962;
	letter-spacing: 2.5px;
}

h3 {
	padding-bottom: 0.5%;
	font-size: 1.1rem;
	font-weight: lighter;
	font-stretch: extra-condensed;
	text-align: left;
}

h4,
span {
	font-weight: inherit;
	font-size: inherit;
	letter-spacing: inherit;
}

span.textFormatterItalic {
	font-style: italic;
}

span.textFormatterBold {
	font-weight: 900;
	color: #505962;
}

h5 {
	margin: 0;
	font-size: 0.9rem;
	font-weight: lighter;
	font-stretch: extra-condensed;
	letter-spacing: normal;
	text-align: center;
}

h6 {
	margin: 0;
	font-style: italic;
	text-align: center;
	letter-spacing: 0px;
	font-size: 0.81rem;
	padding: 1.1% 0;
	font-weight: lighter;
}

pre {
	margin: 0;
}

em {
	font-style: italic;
}

strong {
	font-weight: 600;
}

img {
	width: 100%;
	height: auto;
}

body,
#root {
	margin: auto;
	height: 100%;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ---------------------------------- NAVIGATION SETTINGS ----------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */

header.static,
header.dynamic {
	position: fixed; /*Set to aboslute to act as an overlay of the webpage and wihtout disrupting page layout */
	top: 0; /* Set to display at the very top excluding padding and magrins */
	min-height: 100px;
	height: 5vw; /*Auto to accomodate the height of the children within it */
	width: 100%; /*Allows content dynamic access to full width of the page */
	background-color: none; /*Transparent for the overlay effct */
	/* overflow: hidden; */
	/* animation: 5000ms ease-in 0ms 1 FadeOut;
  transition: opacity 500ms linear 5000ms; */
	/* opacity: 0; */
	z-index: 100;
	transition: opacity 500ms linear 0ms;
}

header.dynamic {
	opacity: 0.1;
	transition: opacity 500ms linear 0ms;
}

/* header.LB {
  background-color: rgba(255, 255, 255, 0.9);
  /* box-shadow: 1px 1px rgba(0, 0, 0, 0.25);
} */

/* @keyframes FadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
*/
header.dynamic:hover {
	opacity: 1;
	transition: opacity 500ms linear 0ms;
}

nav {
	display: flex;
	width: 100%;
	height: 100%;
	padding: 0;
}

nav a {
	opacity: 1;
	transition: opacity 300ms linear 0ms;
}

nav a:hover {
	opacity: 0.6;
	transition: opacity 300ms linear 0ms;
}

.navBarDB,
.navBarLB {
	position: absolute;
	display: flex;
	align-items: flex-start;
	right: 2.7vw;
	top: 1.5vw;
	height: 100%;
	padding-top: 5px;
	width: 52.5vw;
	min-width: -moz-fit-content;
	min-width: fit-content;
	max-width: 1075px;
}

ul.DB,
ul.LB {
	margin: 0;
	padding: 0;
	display: flex;
	height: auto;
	justify-content: flex-end;
	align-items: center;
	flex-flow: row nowrap;
	width: 100%;
}

ul.DB li,
Ul.LB li {
	margin: 0 1.5vw;
	width: fit-content;
	padding: 0;
	list-style: none;
	min-width: 15px;
}

ul.DB li:last-child,
ul.LB li:last-child {
	margin-right: 0;
}

ul.DB li a,
ul.LB li a {
	padding-left: 1px; /*Make the line even */
	font-weight: 400;
	font-size: 1rem;
	font-style: normal;
	font-stretch: extra-condensed;
	letter-spacing: 2.75px;
	text-decoration: none;
	opacity: 1;
	transition: color 500ms linear 0ms;
}

ul.DB li a {
	color: #ffffff;
	font-style: normal;
	font-weight: 100; /* Not sure why it's doing this but white needs much lower font weight to look same as black ?*/
	font-stretch: extra-condensed;
}

ul.DB li a:hover {
	color: #ebebeb;
}

ul.DB li a:active,
ul.DB li a.active {
	border-bottom: 1px solid #ebebeb99;
}

ul.LB li a {
	color: #000000;
}

ul.LB li a:hover {
	color: #505962;
}

ul.LB li a:active,
ul.LB li a.active {
	border-bottom: 1px solid #00000099;
}

.burgerMenu,
.burgerMenuIcon,
.burgerDropdown {
	display: none;
}

.BVLogoDB,
.BVLogoLB {
	position: absolute;
	padding: 0;
	left: 2.7vw;
	top: 1.5vw;
	z-index: 100;
	transition: opacity 500ms linear 0ms;
	/* border: 1px solid red; */
}

.LogoTextLB,
.LogoTextDB {
	display: inline;
	font-size: 33px; /* 1.933vw; */
	padding: 0;
	line-height: fit-content;
}

.closeBurger {
	display: none;
}

@media only screen and (min-width: 2200px) {
	.LogoTextLB,
	.LogoTextDB {
		font-size: 1.75vw; /* 1.933vw; */
	}
}

h1.LogoTextDB,
h1.LogoTextLB {
	letter-spacing: 0.425vw;
	font-weight: 900;
	padding-right: 20px;
	transition: color 500ms linear 0ms;
}

h2.LogoTextDB,
h2.LogoTextLB {
	letter-spacing: 0.4vw;
	font-weight: 100;
	transition: color 500ms linear 0ms;
}

.LogoTextLB {
	color: black;
}

.LogoTextDB {
	color: white;
}

@media only screen and (max-width: 1500px) {
	h1.LogoTextDB,
	h1.LogoTextLB {
		letter-spacing: 6.375px;
	}

	h2.LogoTextDB,
	h2.LogoTextLB {
		letter-spacing: 6px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	ul.DB li,
	ul.LB li {
		margin: 0 1.25vw;
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
	ul.DB li,
	ul.LB li {
		margin: 0 0.6vw;
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1500px) {
	/* .BVLogoDB,
	.BVLogoLB {
		padding: 5px 0 0 0;
	} */

	.LogoTextLB,
	.LogoTextDB {
		font-size: 2.2vw; /* 1.933vw; */
	}
}

@media only screen and (max-width: 999.999px) {
	.LogoTextLB,
	.LogoTextDB {
		font-size: 22px;
	}

	.BVLogoDB,
	.BVLogoLB {
		left: 15px;
		top: 15px;
	}

	.burgerMenu,
	.burgerMenuIcon {
		display: block;
		background: none;
		border: none;
	}

	.burgerMenu {
		position: absolute;
		align-items: center;
		right: 5px;
		top: 2.5px;
		height: auto;
		width: auto;
		background-color: #ffffff00;
		transition: background-color 0.75s ease-in-out 1s;
	}

	.burgerMenuIcon {
		width: auto;
		height: auto;
	}

	.burgerMenuIcon img {
		width: 2.5vw;
		height: 2.5vw;
		min-height: 30px;
		min-width: 30px;
	}

	.navBarDB,
	.navBarLB {
		visibility: hidden; /* hidden by default */
		position: absolute;
		margin: 1vw 0 0;
		right: calc(6vw + 40px);
		top: 2vh;
		width: 0;
		height: auto;
		opacity: 0;
		background-color: #ffffff00;
		z-index: 0; /* high z-index to overlay other content */
		/* transition: opacity 0.75s ease-in-out, visibility 0.75s ease-in-out; */
	}

	/* .burgerMenu:hover,
	.navBarLB.open:hover {
		background-color: #ffffff54;
		transition: background-color 0.75s ease-in-out;
	} */

	.navBarDB.open,
	.navBarLB.open {
		position: fixed;
		top: 0;
		right: 0;
		margin: 0;
		display: flex;
		align-items: center;
		align-content: center;
		visibility: visible;
		background-color: white;
		z-index: 1000; /* high z-index to overlay other content */
		width: 100%;
		height: 100%;
		opacity: 1;
		/* transition: opacity 0.75s ease-in-out, visibility 0.75s ease-in-out; */
	}

	header.static,
	header.dynamic {
		background-color: none;
		padding-bottom: 2.5vw;
	}

	ul.DB,
	ul.LB {
		padding: 0;
		flex-flow: column nowrap;
		width: 100%;
		min-width: 50px;
		height: fit-content;
		/* border: 1px solid red; */
	}

	/* .navBarDB,
	.navBarLB {
		height: fit-content;
		right: 0;
		top: 20px;
		align-items: flex-start;
		justify-content: center; */
	/* border: 1px solid green; */
	/* } */

	ul.DB li,
	Ul.LB li {
		margin: 3vh 0 0;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	ul.DB li a,
	ul.LB li a {
		margin: 0;
		font-size: 1.75rem;
		text-align: center;
		width: fit-content;
		color: #000000;
	}

	ul.DB li a:active,
	ul.DB li a.active,
	ul.LB li a:active,
	ul.LB li a.active {
		border-bottom: 1px solid #000000;
	}

	.closeBurger {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		padding: 20px 20px 50px 25px;
		text-align: right;
		width: 50px;
		height: 50px;
		border: none;
		background: none;
		font-size: 1.75rem;
		font-stretch: condensed;
		font-weight: 100;
		cursor: pointer;
	}
}

@media only screen and (max-width: 500px) {
	.LogoTextLB,
	.LogoTextDB {
		display: block;
	}

	.BVLogoDB,
	.BVLogoLB {
		top: 10px;
	}

	.burgerMenu {
		top: 7.5px;
	}
}

/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------ HOMEPAGE SETTINGS ----------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */

main {
	height: auto;
	min-height: 110vh;
	margin: 0;
	width: 100%;
	overflow: hidden; /* This hides all overflow items on the x-axis so that any excess sizing is cut off fixing the site width to 100%*/
}

section {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
}

button {
	width: 100px;
	height: 40px;
}

.coverPhotoMain,
.coverPhotoCurrentRelease,
.latestEditionBanner {
	/*To keep the cover photo wihin the confines of its container w/ resizing I have used both the cover function and min-height 100vh */
	display: flex;
	align-content: center;
	height: 100vh;
	max-height: 100vh;
	width: 100%;
	overflow: hidden;
	flex-wrap: wrap;
}

/* .latestEditionBanner {
	height: auto;
} */

/* @media screen and (max-width: 853px) {
	.coverPhotoMain,
	.coverPhotoCurrentRelease,
	.latestEditionBanner {
		max-width: 100vw;
	}
} */

.coverPhotoMain img,
.coverPhotoCurrentRelease img,
.latestEditionBanner .editionImageBanner img {
	/*To keep the cover photo wihin the confines of its container w/ resizing I have used both the cover function and min-height 100vh */
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.textContainerCentered {
	position: relative;
	z-index: 5;
	bottom: 5vh;
	padding-left: 12.5vw;
	margin: 0 auto 0 0;
	min-width: fit-content;
	/* border: 5px solid red; */
}

.textContainerCentered h2,
.textContainerCentered h4 {
	display: inline-block;
	margin: 0;
	padding-left: 10px;
	text-align: right;
	font-size: 1.5rem;
	letter-spacing: 0.15rem;
	color: #ffffff;
	min-width: fit-content;
	/* border: 5px solid rgb(0, 8, 255); */
}

.textContainerCentered h2 {
	margin-bottom: 1%;
	padding-right: 10px;
	letter-spacing: 3px;
	font-weight: bold;
	border-right: 1px solid white;
	text-align: left;
}

.textContainerCentered h3 {
	margin-right: -0.1vw; /* to align text */
	color: #ffffff;
	font-size: 1.5rem;
	text-align: right;
}

.buttonContainer {
	/* Button settings for decorative buttons */
	display: flex;
	justify-content: center;
	width: 100%;
}

.buttonContainer button {
	border: 1.5px solid white;
	background: none;
	background-color: rgba(255, 255, 255, 0.1);
	font-weight: 300;
	color: #ffffff;
	border-top-left-radius: 12.5%;
	border-bottom-right-radius: 12.5%;
	transition: background-color 750ms linear 0ms;
}

.buttonContainer button:hover {
	background-color: rgba(255, 255, 255, 0.3);
}

/*  ------ This section of the Homepage's CSS refers to the styling in the Currently Showing Release section   -------- */

.currentlyShowingTitle {
	margin: 7vw 0.7vw 3.5vw 2.7vw;
	width: 100%;
	/* height: 150px; */
}

@media screen and (max-width: 999.99px) {
	.currentlyShowingTitle {
		margin: 60px 15px 30px;
	}
}
/* .caption {
  position: relative;
  top: calc(-7.5vw - 25px);
  padding: 1% 3% 0% 0;
  margin: 0 0 0 auto;
  width: 40%;
  height: auto;
  text-align: end;
  opacity: 0.4;
  transition: opacity 1000ms linear 0ms;
  /* z-index: 1; */
/* border: 1px solid red; */
/* } 
.caption:hover {
  opacity: 1;
} */

/*  ------ This section of the Homepage's CSS refers to the styling in the Latest PSC section   -------- */

.latestPSC,
.earlierPSC,
.latestEdition {
	margin: 0;
	padding: 0;
	width: 100%;
	height: auto;
	min-height: fit-content;
}

.latestPSCHeader,
.earlierPSCHeader,
.latestEditionHeader {
	display: flex;
	margin: 0 2.8vw; /* Margin + Padding accounting for the nav bar + positioning of icons */
	/* padding-top: 2.5%; */
	width: calc(
		100% - 2.8vw - 2.7vw - 2.75px
	); /* 97.5% to centralise this with 1.25% L + R Margin ^^ */
	height: fit-content;
	flex-flow: row wrap;
}

.latestPSCHeader article,
.earlierPSCHeader article,
.latestEditionHeader article {
	margin: 7.5vw 0 3.5vw;
	flex: 1 0 100%;
}

@media screen and (max-width: 999.99px) {
	.latestPSCHeader article,
	.earlierPSCHeader article,
	.latestEditionHeader article {
		margin: 60px 0 30px;
	}
}

.pscTextLogo,
.newEditions,
.curSho {
	padding: 0 0 10px;
	font-weight: bolder;
	font-stretch: extra-condensed;
	letter-spacing: 4px;
	font-size: 1.5rem;
	color: rgb(#505962);
}

h3.releaseBold {
	font-weight: bolder;
	font-size: 1.5rem;
}

h3.releaseLight {
	font-size: 1.5rem;
	letter-spacing: 5px;
}

h4.subHeading {
	font-size: 1.5rem;
	font-style: italic;
}

div.homePiecesContainer1,
div.homePiecesContainer2,
div.homePiecesContainer3 {
	margin: 0 0 0 0;
	padding: 0;
	display: flex;
	flex-flow: row nowrap;
	align-content: stretch;
	width: 100%;
	height: fit-content;
}

div.homePiecesContainer1 {
	justify-content: center;
}

div.homePiecesContainer2 {
	justify-content: space-evenly;
}

div.homePiecesContainer3 {
	justify-content: space-between;
}

a.editionImage {
	height: auto;
	width: auto;
	min-height: fit-content;
	transition: max-height 1000ms linear 0ms;
}

a.editionImage:first-child {
	margin: 0 1% 0 0%;
}

a.editionImage:nth-child(2n) {
	margin: 0 0.5% 0 0.5%;
}

a.editionImage:last-child {
	margin: 0 0% 0 1%;
}

a.editionImage img:hover {
	max-height: 81vh;
}

a.editionImage img {
	width: 100%;
	height: auto;
	max-height: 80vh;
	object-fit: scale-down;
	transition: max-height 1000ms linear 0ms;
}

.latestEditionBanner .editionImageBanner {
	margin: 0;
	width: 100%;
	height: 100%;
	cursor: default;
}

.Gallery {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 2.8vw;
	width: calc(100% - 2.8vw - 2.7vw - 2.75px);
	height: 77.5%; /* This with the header max-height = 100% */
}

.galleryPiece,
.galleryPieceL,
.galleryPieceP {
	margin: 0;
	padding: 0;
	width: 30.1%;
	height: auto;
	min-height: fit-content;
}

.galleryPiece {
	width: 30.1%;
}

.galleryPieceP {
	flex-grow: 1;
}

.galleryPieceL {
	flex-grow: 12;
}

.galleryPieceWide {
	margin: 0;
	padding: 0;
	width: 60%;
	height: auto;
	min-height: fit-content;
}

.galleryPieceThin {
	margin: 0;
	padding: 0;
	width: 20%;
	height: auto;
	min-height: fit-content;
}

.thumbnail {
	margin: 0;
	padding: 0;
	width: 98%;
	height: 77.5%;
	transition:
		width 750ms linear 100ms,
		height 750ms linear 100ms;
}

.galleryPiece:nth-child(2n) .thumbnail {
	margin: 0 auto;
}

.galleryPiece:last-child .thumbnail {
	margin-left: auto;
}

img.ER {
	height: 100%;
	width: 100%;
}

.thumbnail:hover {
	width: 100%;
	height: 80%;
}

.thumbnailCaption {
	margin: 0;
	padding: 2% 0 0 0;
	width: 100%;
	height: auto;
	opacity: 0.75;
}

.thumbnailCaption h6 {
	letter-spacing: 2.5px;
	font-weight: normal;
	text-align: center;
	font-size: 1rem;
}

@media screen and (max-width: 1025px) {
	.coverPhotoMain,
	.coverPhotoCurrentRelease,
	.latestEditionBanner {
		height: auto;
		width: 100%;
	}
}

@media screen and (max-width: 999.99px) {
	.pscTextLogo,
	.newEditions,
	.curSho,
	h3.releaseBold,
	h3.releaseLight,
	h4.subHeading {
		font-size: 1.25rem;
	}

	.thumbnailCaption h6 {
		width: 100%;
		font-size: 1rem;
	}

	.latestPSCHeader,
	.earlierPSCHeader,
	.latestEditionHeader,
	.Gallery {
		margin: 0 15px;
		width: calc(100% - 30px);
	}

	.Gallery {
		flex-flow: row wrap;
		justify-content: space-between;
		align-content: flex-start;
	}
}

@media screen and (max-width: 600px) {
	.coverPhotoMain,
	.coverPhotoCurrentRelease,
	.latestEditionBanner {
		height: auto;
		/* min-height: 50vh; */
		width: 125%;
		margin-left: -12.5%;
	}

	.latestEdition {
		height: fit-content;
		min-height: fit-content;
		max-height: none;
	}

	.latestPSCHeader,
	.earlierPSCHeader,
	.latestEditionHeader,
	.Gallery {
		height: auto;
		min-height: fit-content;
		max-height: none;
	}

	.Gallery {
		justify-content: center;
	}

	div.homePiecesContainer1,
	div.homePiecesContainer2,
	div.homePiecesContainer3,
	.Gallery {
		margin: 0 auto;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-content: flex-start;
		width: 100%;
		height: fit-content;
		/* max-height: 95vh; */
	}

	a.editionImage,
	a.editionImage:first-child,
	a.editionImage:nth-child(2n),
	.galleryPiece,
	.galleryPieceL,
	.galleryPieceP,
	.galleryPieceWide,
	.galleryPiece:nth-child(2n) {
		margin: 0 0 50px;
		/* max-height: 90vh; */
		width: 90%;
	}

	/* .galleryPiece,
	.galleryPieceWide,
	.galleryPiece:nth-child(2n) {
		margin: 0 0 50px;
		/* max-height: 90vh; */
	/* flex: 1 0 100%; */
	/* }  */

	a.editionImage:last-child {
		margin: 0;
	}

	.galleryPieceThin {
		display: none;
	}

	div.homePiecesContainer1 img,
	div.homePiecesContainer2 img,
	div.homePiecesContainer3 img {
		flex: 1 0 100%;
		margin: 0 auto;
		width: auto;
		height: auto;
		/* max-width: 100%; */
		max-height: 80vh;
	}

	a.editionImage,
	a.editionImage:hover,
	a.editionImage:first-child,
	a.editionImage:nth-child(2n),
	.thumbnail:hover,
	.thumbnail {
		display: flex;
		flex-flow: row wrap;
		width: 100%;
	}

	a.editionImage img,
	a.editionImage img:hover,
	.thumbnail img {
		margin: 0 auto;
		max-height: 80vh;
		width: auto;
		height: auto;
		max-width: 100%;
	}

	/* div.homePiecesContainer1 img:hover,
	div.homePiecesContainer2 img:hover,
	div.homePiecesContainer3 img:hover {
		height: auto;
		max-width: 100%;
		max-height: 82.5%;
	} */
}

/* .NewsCarouselSection {
	position: relative;
	display: flex;
	left: -2.5vw;
	flex-flow: nowrap row;
	margin: 10vw 0 0 0;
	padding: 0;
	height: 100vh;
	width: calc(105vw - 17px); /*Scroll
	border: 1px solid blue;
}

.PrevSlideNC,
.NextSlideNC {
	position: relative;
	align-self: center;
	width: 2.381%;
	height: 50%;
	background: white;
	z-index: 100;
}

.PrevSlideNC {
	left: 2.381%;
}

.NextSlideNC {
	right: 2.381%;
}

.NC-Slide-Container {
	display: flex;
	position: relative;
	margin-bottom: -100vh;
	width: 100.238vw;
	height: 100%;
	overflow: hidden;
}

.NC-SlideActive,
.NC-SlideInactive {
	position: relative;
	flex: 0 0 100%;
	min-width: 100%;
	transition: all 1.25s ease-in-out 0s;
	will-change: transform;
}

.NC-Slide-Image {
	display: block;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.NC-Slide-Image-Caption {
	position: relative;
	width: fit-content;
	padding: 10%;
	top: -80%;
	left: 20%;
	background-color: rgba(245, 245, 245, 0.718);
	border-radius: 10px;
} */

/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ----------------------------------------- ARTIST PAGE ------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */

/* General Styles */
.ArtistDescription,
.ArtistWorks {
	display: block;
	width: calc(100% - 2.8vw - 2.7vw - 2.75px);
	min-height: fit-content;
}

/* Artist Description */
.ArtistDescription {
	/* align-items: flex-start;
    justify-content: flex-start;
    flex-flow: row wrap; */
	padding: 65px 0 0;
	margin: 0 2.8vw;
}

.ArtistWorks {
	margin: 0 calc(2.7vw + 2.75px) 0 2.8vw;
	padding: 0;
}

/* Titles */
.introArtistTitle {
	margin: 0;
	padding-top: 72px;
	height: auto;
}

.artistTitle,
.borderedArtistTitle {
	margin: 0;
	padding-top: 75px;
	height: auto;
}

h1.artistName,
h2.artistName,
span.setTitle {
	/* margin: calc(5vh + 75px) 0 calc(1.25vh + 75px);*/
	font-size: 1.25rem;
	letter-spacing: 0px;
	color: #505962;
	font-weight: 900;
	font-stretch: extra-condensed;
}

span.setTitle {
	font-weight: 100;
	font-style: italic;
	color: black;
}

/* .borderedArtistTitle {
    /* padding-top: 100px; */
/* border-top: 1px solid grey;
} */

/* Text Containers */
.artistTextContainer,
.artistText {
	/* margin: calc(15vh - 80px) 0; If Artist chanegs use this */
	margin: 25px 0 0;
	height: auto;
}

.artistText p {
	font-size: 1rem;
	letter-spacing: 0px;
	font-weight: 500;
	font-stretch: extra-condensed;
	line-height: 125%;
}

/* @media only screen and (max-height: 1000px) {
}

@media only screen and (max-height: 600px) {
} */

button.setPDF {
	margin: 25px 0 75px;
	width: fit-content;
	height: fit-content;
	text-align: left;
	font-size: 1rem;
	padding: 0;
	cursor: pointer;
	font-weight: 200;
	letter-spacing: 0;
	font-stretch: extra-condensed;
	text-decoration: underline;
	background: none;
	border: none;
}

/* Images */
.artistLeadImageContainer {
	margin: 25px 0 0;
	padding: 0;
	height: fit-content;
}

.artistLeadImageContainer img {
	width: 100%;
	max-width: fit-content;
	/* min-height: fit-content; */
	height: auto;
}

/* Image Captions */
.artistImageCaption {
	display: inline-block;
	height: fit-content;
	width: 100%;
	border-bottom: 1px solid #505962;
	padding-bottom: 40px;
}

.artistImageCaption h2,
.artistImageCaption h3,
.artistImageCaption h3 em,
.artistImageCaption p,
.artistImageCaption h6 {
	margin-left: auto;
	font-size: 1rem;
	width: fit-content;
	text-align: right;
	letter-spacing: 1px;
	font-weight: 200;
	opacity: 1;
}

.artistImageCaption h2 {
	letter-spacing: 1px;
	color: #505962;
	font-weight: 900;
	font-stretch: extra-condensed;
	margin-left: auto;
}

.artistImageCaption .enquire {
	display: block;
	margin-left: auto;
	width: fit-content;
	text-align: right;
	font-weight: 900;
	font-size: 1rem;
	letter-spacing: 0.05rem;
	font-stretch: extra-condensed;
	color: #505962;
}

.paraBreak,
.pricing,
.enquire {
	margin-bottom: 2.25vh;
}

/* Carousel */
.CarouselContainer {
	display: flex;
	padding: 0;
	margin: 0;
	height: fit-content;
	width: 100%;
}

.setContainer {
	margin: 50px 0;
	height: fit-content;
	width: 100%;
	scroll-padding-top: 0;
	scroll-margin-block-start: 0;
}

.setContainer:first-child {
	margin-top: 50px;
}

.pieceContainer {
	width: 100%;
	height: fit-content;
}

.SContainer {
	display: flex;
	width: 100%;
	height: calc(84vh + 40px);
}

.STextWrapper {
	display: inline-flex;
	margin: 0; /*needs to be proportional to max width below */
	/* height: fit-content; */
	align-self: last baseline;
	order: 1;
}

.SImageWrapper {
	flex: 1 0 auto;
	display: inline-flex;
	margin: 0;
	width: auto;
	justify-content: flex-end;
	min-height: fit-content;
	align-self: stretch;
	align-items: center;
	max-height: 98vh;
	order: 2;
}

.SImageWrapper img {
	/* min-width: 30%; */
	width: auto;
	height: auto;
	max-height: 84vh;
	margin-bottom: 40px;
}

.carouselNav {
	margin: -25px 0 0 auto;
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	height: auto;
}

.carouselNavButtonRight,
.carouselNavButtonLeft,
.activeCarouselNavButtonRight,
.activeCarouselNavButtonLeft {
	display: inline-block;
	margin: 0 0.25vw;
	padding: 0;
	height: 7px;
	width: 7.5px;
	border-radius: 50%;
	border: 1px solid #505962;
	overflow: hidden; /* Added to clip the pseudo-element */
	position: relative; /* Required for absolute positioning of the pseudo-element */
}

.carouselNavButtonRight::before,
.carouselNavButtonLeft::before,
.activeCarouselNavButtonRight::before,
.activeCarouselNavButtonLeft::before {
	content: "";
	position: absolute;
	top: -50%;
	right: -50%;
	background-color: white; /* use trans for non white backgrounds */
	background-image: linear-gradient(to right, #505962 50%, white 50%);
	background-size: 200% 100%;
	background-repeat: no-repeat;
	height: 200%; /* Make it large enough to cover the entire button */
	width: 200%;
	border-radius: 50%;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-fill-mode: forwards; /* Ensures the end state of the animation is retained */
}

.carouselNavButtonRight::before {
	animation-name: gradientShiftRightReverse;
}

.activeCarouselNavButtonRight::before {
	animation-name: gradientShiftRight;
}

.carouselNavButtonLeft::before {
	animation-name: gradientShiftLeft;
}

.activeCarouselNavButtonLeft::before {
	animation-name: gradientShiftLeftReverse;
}

@keyframes gradientShiftRightReverse {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: -100% 0%;
	}
}

@keyframes gradientShiftRight {
	0% {
		background-position: 100% 0%;
	}
	100% {
		background-position: 0% 0%;
	}
}

@keyframes gradientShiftLeftReverse {
	0% {
		background-position: -100% 0%;
	}
	100% {
		background-position: 0% 0%;
	}
}

@keyframes gradientShiftLeft {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 0%;
	}
}

button.LeftSlideButton,
button.RightSlideButton {
	position: relative;
	/* align-self: center; */
	display: inline-block;
	margin: 0;
	padding: 0;
	width: 50px;
	height: 20px;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0);
	border: none;
}

button.LeftSlideButton {
	/*Aligns with home and contact (works with the width's below)*/
	margin-right: auto;
	left: -15px;
}
button.RightSlideButton {
	margin-left: auto;
	right: -20px;
}

@media only screen and (min-width: 2200px) {
	.SImageWrapper img {
		max-width: calc(18vw + 739.43px);
	}

	.carouselNav {
		width: calc(18vw + 739.43px);
	}

	.artistTextContainer,
	.artistText,
	.borderedArtistTitle,
	.introArtistTitle,
	.artistTitle,
	h1.artistName,
	h2.artistName,
	span.setTitle,
	.artistLeadImageContainer {
		width: calc(24.675vw + 20px); /* tailored to text size*/
	}

	.STextWrapper {
		min-width: calc(24.675vw + 20px);
		max-width: calc(24.675vw + 20px);
	}
}

@media only screen and (min-width: 1500px) and (max-width: 2199.99px) {
	.SImageWrapper img {
		max-width: calc(17.9vw + 573.85px);
	}

	.carouselNav {
		width: calc(17.9vw + 573.85px);
	}

	.artistTextContainer,
	.artistText,
	.borderedArtistTitle,
	.introArtistTitle,
	.artistTitle,
	h1.artistName,
	h2.artistName,
	span.setTitle,
	.artistLeadImageContainer {
		width: calc(
			165.04px + 4.4vw + 154.43px + 4.25vw
		); /* tailored to text size*/
	}

	.STextWrapper {
		min-width: calc(165.04px + 4.4vw + 154.43px + 4.25vw);
		max-width: calc(165.04px + 4.4vw + 154.43px + 4.25vw);
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1499.99px) {
	.SImageWrapper img {
		max-width: calc(15vw + 509.67px);
	}

	.carouselNav {
		width: calc(15vw + 509.67px);
	}
}

@media only screen and (min-width: 1041px) and (max-width: 1199.99px) {
	.SImageWrapper img {
		max-width: calc(7.2vw + 491.94px);
	}

	.carouselNav {
		width: calc(7.2vw + 491.94px);
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1040.99px) {
	.SImageWrapper img {
		max-width: calc(7.2vw + 491.94px);
	}

	.carouselNav {
		width: calc(7.2vw + 491.94px);
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1499.99px) {
	.artistTextContainer,
	.artistText,
	.borderedArtistTitle,
	.introArtistTitle,
	.artistTitle,
	h1.artistName,
	h2.artistName,
	span.setTitle,
	.artistLeadImageContainer {
		width: calc(8.96vw + 83.75px + 11.28vw + 64px);
		max-height: fit-content;
	}

	.STextWrapper {
		min-width: calc(8.96vw + 83.75px + 11.28vw + 64px);
		max-width: calc(8.96vw + 83.75px + 11.28vw + 64px);
	}
}

@media only screen and (min-width: 1px) and (max-width: 999.99px) {
	.ArtistWorks,
	.ArtistDescription {
		margin-left: 15px;
		margin-right: 15px;
		width: calc(100% - 30px);
	}

	.carouselNav {
		width: 100%;
	}
}

@media only screen and (min-width: 760px) and (max-width: 999.99px) {
	.artistTextContainer,
	.artistText,
	.borderedArtistTitle,
	.introArtistTitle,
	.artistTitle,
	h1.artistName,
	h2.artistName,
	span.setTitle,
	.artistLeadImageContainer {
		width: 351.21px;
	}

	.STextWrapper {
		width: 40vw;
		max-width: 351.21px;
	}

	.SImageWrapper {
		justify-content: flex-end;
	}

	.SImageWrapper img,
	.carouselNav {
		max-width: 45vw;
	}
}

@media only screen and (max-width: 759.99px) {
	.artistLeadImageContainer img {
		width: 100%;
		height: auto;
		max-height: fit-content;
		min-height: 1px;
	}

	.SContainer {
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
		height: fit-content;
	}

	.STextWrapper,
	.SImageWrapper {
		flex: 1 0 100%;
		margin: 0;
		padding: 0;
		max-width: 100%;
		min-width: 100%;
		max-height: fit-content;
	}

	.STextWrapper {
		order: 2;
	}

	.SImageWrapper {
		order: 1;
		justify-content: center;
	}

	.artistImageCaption {
		padding-bottom: 30px;
	}

	.SImageWrapper img {
		max-width: 100%;
		max-height: 95vh;
		margin-bottom: 30px;
		width: auto;
		height: auto;
	}
	.carouselNav {
		position: relative;
		margin: calc(-5vw - 25px) 0 0 0;
	}

	button.LeftSlideButton {
		left: -17.5px;
	}
	button.RightSlideButton {
		right: -23.5px;
	}

	.pieceContainer {
		display: flex;
		flex-wrap: wrap;
	}

	.carouselNav {
		flex: 1 0 100%;
		order: 1;
	}

	.CarouselContainer {
		flex: 1 0 100%;
		order: 2;
	}
}

@media only screen and (max-width: 599.99px) {
	.artistTextContainer,
	.artistText,
	.borderedArtistTitle,
	.introArtistTitle,
	.artistTitle,
	h1.artistName,
	h2.artistName,
	span.setTitle,
	.artistLeadImageContainer {
		width: 100%;
	}
}

.pieceNameDate {
	max-width: fit-content;
	margin-left: auto;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* -------------------------------------- ARTISTS PAGE -------------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */

/* ------------------------------------------------------------------------------------------------ */
/*  This is the styling used to create a static nav bar so it doesn't compete with the search bar   */
/* ------------------------------------------------------------------------------------------------ */

#ResultsContainerGrid,
#ResultsContainerList {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	align-content: space-between;
	justify-content: flex-start;
	justify-items: flex-start;
	height: auto;
	min-height: fit-content;
	margin: 19vw 2.7vw 2% 2.8vw;
	width: calc(100% - 2.7vw - 2.8vw - 2.75px);
	animation: loading 800ms linear forwards;
}

a.resultGrid {
	flex: 0 0 24%;
	margin: 0 1.33% 6vw 0;
	padding: 0;
	overflow: hidden;
	/* border: 2px solid rgb(255, 0, 157); */
}

a.resultGrid:nth-child(4n) {
	margin: 0 0 6vw 0;
}

@media only screen and (min-width: 1000px) and (max-width: 1399px) {
	a.resultGrid {
		flex: 0 0 32%;
		margin: 0 2% 6vw 0;
	}

	a.resultGrid,
	a.resultGrid:nth-child(4n) {
		margin: 0 2% 6vw 0;
	}

	a.resultGrid:nth-child(3n) {
		margin: 0 0 6vw 0;
	}
}

@media only screen and (min-width: 650px) and (max-width: 999px) {
	#ResultsContainerGrid,
	#ResultsContainerList {
		margin: 19vw 15px 2%;
		width: calc(100% - 30px);
	}

	a.resultGrid,
	a.resultGrid:nth-child(4n) {
		flex: 0 0 48%;
		margin: 0 4% 6vw 0;
	}

	a.resultGrid:nth-child(2n) {
		margin: 0 0 6vw 0;
	}
}

@media only screen and (max-width: 649px) {
	#ResultsContainerGrid,
	#ResultsContainerList {
		margin: 19vw 15px 2%;
		width: calc(100% - 30px);
	}

	a.resultGrid,
	a.resultGrid:nth-child(4n) {
		flex: 1 0 100%;
		margin: 0 0 50px;
		max-width: 100%;
		width: auto;
		max-height: 95vh;
		height: auto;
	}
}

.picture {
	/* display: flex;
  justify-content: center; */
	height: 85%;
	width: auto;
	/* border: 2px solid rgb(212, 255, 0); */
}
.picture img {
	margin: 0;
	padding: 0;
	width: 100%;
	height: auto;
	/* border: 1px solid rgb(197, 162, 3); */
}

h2.artistLead {
	margin: 25px 0 10%;
	height: 10%;
	width: 100%;
	font-size: 1rem;
	text-align: center;
	font-weight: normal;
	font-stretch: extra-condensed;
	letter-spacing: 0.1rem;
	color: #000000;
	/* border: 2px solid rgb(9, 206, 255); */
}

a.resultList {
	display: inline-block;
	margin: 0.5vw;
	width: 100%;
	/* border: 2px solid rgb(255, 0, 157); */
}

#ResultsContainerList h2.artistLeadL,
#ResultsContainerList h2.artistLeadR {
	position: relative;
	margin: auto;
	width: 100%;
	font-size: 2rem;
	font-weight: 200;
	font-stretch: normal;
	text-align: center;
	opacity: 1;
	transition: opacity 400ms linear 0ms;
}

#ResultsContainerList h2.artistLeadL:hover,
#ResultsContainerList h2.artistLeadR:hover {
	opacity: 0.5;
}

@media only screen and (max-width: 999px) {
	h2.artistLead {
		font-size: 1.25rem;
		margin-bottom: 25px;
	}
}

/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ---------------------------------------- ON DISPLAY ---------------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
.onDisplay {
	display: flex;
	flex-flow: row wrap;
	height: fit-content;
	width: calc(
		100% - 2.8vw - 2.7vw - 2.75px
	); /*two margins + letter spacing on "contact " properly aligned! */
	margin: 17.5vw 0 0 2.8vw;
	padding: 0;
}

.onDisplayBox {
	flex: 1 0 calc(33.33% - 2vw);
	margin: 0 1.75vw 6vw 0;
	max-width: 30.5vw;
	max-height: fit-content;
	height: auto;
}
.onDisplayBox:nth-child(3n) {
	margin-right: 0;
}

.onDisplayBox h2 {
	margin: 0;
	padding: 1.25vw 0;
	font-size: 1rem;
	letter-spacing: 3px;
	text-align: center;
	font-weight: 100;
	color: black;
	/* border: 1px solid red; */
}

.onDisplayBox img {
	margin: 0 0 0.75vw;
	padding: 0;
	width: 100%;
	height: auto;
}

article.onDisplayBoxText {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	align-content: flex-start;
	justify-content: flex-end;
	margin: 0;
	padding: 0;
	height: auto; /* Updated to auto to fit the content */
	width: 100%;
}

article.onDisplayBoxText h3 {
	display: inline;
	height: auto;
	font-size: 1rem;
	font-weight: 900;
	font-stretch: extra-condensed;
	letter-spacing: 0px;
	color: #505962;
	text-align: end;
	/* border: 1px solid red; */
}

article.onDisplayBoxText h4 {
	display: inline;
	height: auto;
	text-align: end;
	font-size: 1rem;
	font-style: italic;
	letter-spacing: 0px;
	/* border: 1px solid red; */
}

article.onDisplayBoxText p {
	text-align: justify;
	margin: 0.75vw 0 0;
	font-size: 1rem;
	width: 100%;
}

@media only screen and (max-width: 1000px) {
	.onDisplay {
		justify-content: space-between;
		height: fit-content;
		width: calc(100% - 30px);
		margin: 17.5vw 0 0 15px;
	}

	.onDisplayBox {
		flex: 1 0 calc(48%);
		margin: 0 10px 2vw 0;
		max-width: calc(50% - 5px);
	}

	.onDisplayBox:nth-child(2n) {
		margin-right: 0;
	}
}

@media only screen and (max-width: 600px) {
	.onDisplayBox {
		flex: 1 0 100%;
		max-width: 100%;
		margin-right: 0;
	}

	.onDisplayBox h2 {
		margin: 25px 0;
		font-size: 1.25rem;
	}

	.onDisplayBox img {
		margin: 0;
	}

	article.onDisplayBoxText {
		margin-top: 25px;
		justify-content: center;
		padding-bottom: 25px;
		border-bottom: 1.5px solid #505962b0;
	}

	article.onDisplayBoxText p,
	article.onDisplayBoxText h3,
	article.onDisplayBoxText h4 {
		text-align: center;
	}

	article.onDisplayBoxText h3,
	article.onDisplayBoxText h4 {
		font-size: 1.25rem;
	}
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ----------------------------------------- OD Page ---------------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */

/* Styling for the outer container */
.OnDisplayItemPage {
	padding: 0;
	margin: 0 2.7vw 0 2.8vw;
	width: calc(100% - 2.8vw - 2.7vw - 2.75px);
	height: auto;
}

.ODContentContainer {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	margin: 0 0;
	padding-top: 30vh;
	width: 100%;
	min-height: fit-content;
}

.ODContentTextBox {
	flex: 1 0 auto;
	display: flex;
	flex-flow: row wrap;
	align-content: flex-end;
	justify-content: flex-end;
	min-height: fit-content;
}

.titles {
	display: flex;
	/*justify-content: flex-end;
	justify-items: flex-end; */
	height: fit-content;
	width: 100%;
	/* border: 1px solid #000000; */
}

.titles h1,
.titles h2 {
	display: inline-flex;
	align-items: flex-start;
	justify-content: flex-end;
	/* width: fit-content; */
	font-size: 1.2rem;
	letter-spacing: 0px;
	/* border: 1px solid #000000; */
}

.titles h1 {
	flex-shrink: 1;
	flex-grow: 1;
	text-align: end;
	min-width: fit-content;
}

.titles h2 {
	height: auto;
	font-weight: 100;
	font-style: italic;
	text-align: justify;
	text-align-last: left;
}

.ODContentTextBox h3,
.ODContentTextBox p,
.ODContentTextBox .link,
.ODContentTextBox article {
	flex: 1 0 100%;
	font-size: 1rem;
	letter-spacing: normal;
	max-width: 100%;
	/* border: 1px solid red; */
}

.titles,
.ODContentTextBox p {
	margin-bottom: 2.75vw;
}

.ODContentTextBox h3 {
	margin-bottom: 1.75vw;
}

.ODContentTextBox .link {
	height: fit-content;
	text-decoration: underline;
}

.ODContentTextBox h3 {
	text-align: end;
}

/* Styling for the image box */
.ODContentImageBox {
	flex: 1 1 auto;
	display: flex;
	flex-flow: row wrap;
	align-items: flex-end;
	align-content: center;
	justify-content: flex-end;
	height: auto;
	min-height: fit-content;
	background-color: #fff;
	border-radius: 5px;
}

.ODImageCaption {
	flex-flow: 0 0 100%;
	min-width: 100%;
}

.ODImageCaption p {
	padding-top: 10px;
	font-size: 0.75rem;
	letter-spacing: normal;
	max-width: 100%;
	text-align: right;
}

.ODContentImageBox img {
	max-height: 84vh;
	width: fit-content;
}

.ODContentImageBox iframe {
	max-height: 84vh;
	/* height: ; */
}

.backButton {
	padding: 0;
	margin: 0;
	margin-right: auto;
	background-color: white;
	border: none;
	width: fit-content;
	height: fit-content;
}

/* @keyframes shimmer {
	0% {
	  background-position: -468px 0;
	}
	100% {
	  background-position: 468px 0;
	}
}
  
.infScrollBox {
	animation-duration: 1.25s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: shimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background-size: 800px 104px;
	height: 96px;
	position: relative;
} */

.infScrollBox {
	height: 10px;
	width: 100%;
}

@media only screen and (min-width: 2200px) {
	.ODContentImageBox img,
	.ODContentImageBox iframe {
		max-width: calc(18vw + 737.43px);
	}

	.ODContentImageBox iframe {
		height: calc((18vw + 737.43px) * (9 / 16));
	}

	.ODContentTextBox {
		max-width: calc(24.675vw + 20px);
		min-width: calc(24.675vw + 20px);
	}
}

@media only screen and (min-width: 1500px) and (max-width: 2199px) {
	.ODContentImageBox img,
	.ODContentImageBox iframe {
		max-width: calc(17.9vw + 571.85px);
	}

	.ODContentImageBox iframe {
		height: calc((17.9vw + 571.85px) * (9 / 16));
	}

	.ODContentTextBox {
		max-width: calc(165.04px + 4.45vw + 154.43px + 4.3vw);
		min-width: calc(165.04px + 4.45vw + 154.43px + 4.3vw);
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	.ODContentImageBox img,
	.ODContentImageBox iframe {
		max-width: calc(
			15vw + 508.92px
		); /*exclude paddding at home and end of contact*/
	}

	.ODContentImageBox iframe {
		height: calc((15vw + 508.92px) * (9 / 16));
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
	.ODContentImageBox img,
	.ODContentImageBox iframe {
		max-width: calc(7.2vw + 487.94px);
	}

	.ODContentImageBox iframe {
		height: calc((7.2vw + 487.94px) * (9 / 16));
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1499px) {
	.ODContentTextBox {
		min-width: calc(8.96vw + 83.75px + 11.28vw + 64px);
		max-width: calc(8.96vw + 83.75px + 11.28vw + 64px);
	}
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
	.OnDisplayItemPage {
		width: calc(100% - 30px);
		margin-left: 15px;
		margin-right: 0;
	}

	.ODContentContainer {
		width: 100%;
		flex-flow: row wrap;
		height: fit-content;
	}

	.ODContentTextBox {
		margin-right: 25px;
		justify-content: flex-start;
		min-width: 352.21px;
		max-width: 352.21px;
	}

	.ODContentImageBox {
		width: 50%;
	}

	.ODContentImageBox img,
	.ODContentImageBox iframe {
		margin-top: 25px;
		max-width: 100%;
	}

	.ODContentImageBox iframe {
		width: 100%;
		height: calc(500px * (9 / 16));
	}
}

@media only screen and (max-width: 799px) {
	.ODContentImageBox iframe {
		width: 100vw;
		height: calc(100vw * (9 / 16));
	}
}

@media only screen and (max-width: 599px) {
	.OnDisplayItemPage {
		width: calc(100% - 30px);
		margin-left: 15px;
		margin-right: 0;
	}

	.ODContentContainer {
		width: 100%;
		flex-flow: row wrap;
		height: fit-content;
	}

	.ODContentTextBox {
		width: 100%;
	}

	.ODContentImageBox img,
	.ODContentImageBox iframe {
		margin-top: 25px;
		max-width: 100%;
	}

	.ODContentImageBox iframe {
		height: calc((100vw - 45px) * (9 / 16));
	}
}

/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ---------------------------------------- NEWS PAGE --------------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */

#News {
	margin: 7.5vw auto 0;
	padding: 0;
	width: 80vw;
	height: 80vh;
	border: 1px solid red;
}

#News h1 {
	margin: auto;
	height: fit-content;
	width: fit-content;
	border: 1px solid red;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ---------------------------------------- ABOUT PAGE -------------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */

section.About {
	padding: 0;
	margin: calc(5vw - 2vh + 100px) 2.7vw 0 2.8vw;
	min-height: calc(90vh - 13vw);
	width: calc(100% - 2.8vw - 2.7vw - 2.75px);
	height: fit-content;
}

.aboutContent {
	display: flex;
	flex-flow: row nowrap;
	height: fit-content;
	overflow: visible;
	width: 100%;
}

article.aboutText {
	display: inline-block;
	height: fit-content;
}

p.aboutStyle {
	padding: 5px 0;
	font-size: 1rem;
	min-height: fit-content;
}

div.aboutImage {
	display: inline-flex;
	align-items: center;
	padding: 0;
	margin-left: auto;
	min-height: fit-content;
}

div.aboutImage img {
	width: 100%;
	height: auto;
}

@media only screen and (min-width: 2200px) {
	div.aboutImage img {
		width: calc(18vw + 737.43px);
	}

	article.aboutText {
		max-width: calc(24.675vw + 20px);
		min-width: calc(24.675vw + 20px);
	}
}

@media only screen and (min-width: 1500px) and (max-width: 2199px) {
	div.aboutImage img {
		max-width: calc(17.9vw + 571.85px);
	}

	article.aboutText {
		max-width: calc(165.04px + 4.45vw + 154.43px + 4.3vw);
		min-width: calc(165.04px + 4.45vw + 154.43px + 4.3vw);
	}
}

@media only screen and (min-width: 1500px) and (max-width: 2199px) {
	div.aboutImage img {
		max-width: calc(17.9vw + 571.85px);
	}

	article.aboutText {
		max-width: calc(165.04px + 4.45vw + 154.43px + 4.3vw);
		min-width: calc(165.04px + 4.45vw + 154.43px + 4.3vw);
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	div.aboutImage img {
		max-width: calc(
			15vw + 508.92px
		); /*exclude paddding at home and end of contact*/
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
	div.aboutImage img {
		max-width: calc(7.2vw + 487.94px);
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1499px) {
	article.aboutText {
		min-width: calc(8.96vw + 83.75px + 11.28vw + 64px);
		max-width: calc(8.96vw + 83.75px + 11.28vw + 64px);
	}
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
	section.About {
		flex-flow: row wrap;
		width: calc(100% - 30px);
		margin-left: 15px;
		margin-right: 0;
	}

	.aboutContent {
		width: 100%;
		flex-flow: row wrap;
		height: fit-content;
	}

	article.aboutText {
		margin: 0 25px 0 0;
		justify-content: flex-start;
		min-width: 352.21px;
		max-width: 352.21px;
	}

	div.aboutImage {
		margin: 40px 0 0 0;
		width: 50%;
	}

	div.aboutImage img {
		max-width: 100%;
	}
}

@media only screen and (max-width: 800px) {
	section.About {
		flex-flow: row wrap;
		width: calc(100% - 30px);
		margin-left: 15px;
		margin-right: 0;
	}

	.aboutContent {
		width: 100%;
		flex-flow: row wrap;
		height: fit-content;
	}

	div.aboutImage {
		margin: 40px 0 5vw 0;
		width: 100%;
		max-width: max-content;
		justify-self: center;
	}
}

@media only screen and (max-width: 599px) {
	article.aboutText {
		margin-top: 25px;
		width: 100%;
	}

	p.aboutStyle {
		text-align: justify;
	}
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ----------------------------------------- CONTACT ---------------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */

.contactPage {
	display: flex;
	flex-flow: row nowrap;
	margin: 1.25vw 1.35vw;
	width: calc(100% - 2.7vw - 2.75px);
	/* min-height: calc(100vh - 2.5vw); */
	height: fit-content;
	/* min-height: 900px; */
	/* border: 1px solid black; */
}

.contactLeft,
.contactRight {
	display: flex;
	flex-flow: row wrap;
}

.contactLeft {
	padding: 80px 0 0;
	margin-left: 1.35vw;
	height: fit-content;
	/* border: 1px solid red; */
}

.leftTop {
	text-align: justify;
	width: 100%;
	max-height: fit-content;
	border-bottom: 1px solid black;
	padding-bottom: 1rem;
}

.leftBottom {
	text-align: justify;
	width: 100%;
	max-height: fit-content;
}

.leftBottom p {
	line-height: 1rem;
}

.contactLeft h2,
.contactLeft p,
.contactLeft a {
	font-weight: 100;
	letter-spacing: 0px;
	color: #000000;
}

.contactLeft p,
.contactLeft a {
	font-size: 0.925rem;
	width: fit-content;
}

.contactLeft h2 {
	font-size: 1.2rem;
}

.contactLeft a {
	color: #505962;
	text-decoration: underline;
}

.contactLeft a:active {
	color: #505462;
}

.contactRight {
	display: flex;
	align-self: stretch;
	align-content: flex-start;
	margin-right: calc(1.35vw);
	min-width: fit-content;
	flex-grow: 1;
	padding: 80px 0 30px;
	/* border: 1px solid rgb(255, 0, 0); */
}

.contactRight img {
	margin-left: auto;
	width: auto;
	height: auto;
}

@media only screen and (min-width: 2200px) {
	.contactLeft,
	.contactRight {
		padding-top: 120px;
	}

	.contactRight img {
		max-width: calc(18vw + 737.43px);
		height: 1275.04px;
	}

	.contactLeft {
		max-width: calc(24.675vw + 20px);
		min-width: calc(24.675vw + 20px);
	}
}

/* @media only screen and (min-width: 2597px) {
	.contactRight img {
		height: 1215.37px;
	}
} */

@media only screen and (min-width: 1500px) and (max-width: 2199px) {
	.contactRight img {
		max-width: calc(17.9vw + 571.85px);
		/* height: 850.566px; */
	}

	.contactLeft {
		max-width: calc(165.04px + 4.45vw + 154.43px + 4.3vw);
		min-width: calc(165.04px + 4.45vw + 154.43px + 4.3vw);
	}
}

/* @media only screen and (min-width: 1666px) and (max-width: 1691.99px) {
	.contactRight img {
		height: 892.232px;
	}
} */

/* @media only screen and (min-width: 1500px) and (max-width: 1665.99px) {
	.contactRight img {
		height: 918.58px;
	}
} */

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	.contactRight img {
		max-width: calc(15vw + 508.92px);
		/* height: 741.378px; */
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
	.contactRight img {
		max-width: calc(7.2vw + 487.94px);
		/* height: 706.42px; */
	}
}

/* @media only screen and (min-width: 1000px) and (max-width: 1080px) {
	.contactRight img {
		height: 738px;
	}
} */

@media only screen and (min-width: 1000px) and (max-width: 1499px) {
	.contactLeft {
		min-width: calc(8.96vw + 83.75px + 11.28vw + 64px);
		max-width: calc(8.96vw + 83.75px + 11.28vw + 64px);
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
	.contactLeft {
		margin-right: 0.45vw;
	}
}

@media only screen and (max-width: 999.99px) {
	.contactPage {
		width: calc(100% - 7.5px - 15px);
		height: fit-content;
		margin-left: 7.5px;
		margin-right: 0;
	}

	.contactLeft {
		margin: 0 25px 0 1vw;
		justify-content: flex-start;
		max-width: 352.21px;
		min-width: 352.21px;
	}

	.contactRight {
		align-content: center;
		width: 50%;
		min-width: 1px;
		margin-right: 0;
		margin-bottom: 100px;
		padding-top: 80px;
	}

	.contactRight img {
		position: relative;
		/* right: 5vw; */
		width: auto;
		max-width: 100%;
		max-height: 500px;
		/* max-height: none; */
	}
}

@media only screen and (max-width: 810px) {
	.contactRight {
		display: none;
	}
}

/* @media only screen and (max-width: 670px) {
	.contactRight{
		display: none;
	}	
} */

@media only screen and (max-width: 550px) {
	.contactLeft {
		margin: 0 7.5px;
		justify-content: flex-start;
		width: 100%;
		max-width: 100%;
		min-width: 1px;
	}
}

/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ----------------------------------------- FOOTER ----------------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */

footer {
	/* The footer is partuoned into 3 segments L/M/R containing differant contain */
	display: flex;
	margin: 5vw 2.7vw 2vw;
	height: auto;
	min-height: fit-content;
	width: 93.5vw;
	opacity: 1;
}

/* ------------------------------------------------------------------------------------------------ */
/* ---- This is the left section of the footer containing contact info / copyright declaration ---- */
/* ------------------------------------------------------------------------------------------------ */

footer .left {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-end;
	align-content: center;
	margin: 0;
	width: 100%;
	min-width: fit-content;
	height: calc(100% - 2.5vw);
	/* border: 1px solid red; */
}

a.contactLink {
	margin: 0 0 20px;
	padding: 0;
	width: fit-content;
	height: auto;
	text-align: left;
	font-stretch: extra-condensed;
	font-weight: lighter;
	line-height: auto;
	font-size: 1.75rem;
	letter-spacing: 7px;
	/* border-bottom: 1px solid grey; */
	/* border: 1px solid rgb(174, 0, 255); */
}

a.contactLink h1 {
	font-size: 1.5rem;
	letter-spacing: 4px;
}

@media only screen and (min-width: 2200px) {
	a.contactLink h1 {
		font-size: 48px;
	}
}

footer nav {
	/* margin-top: -5%; */
	margin: 25px 0;
	padding: 0;
	width: 100%;
	height: auto;
	/* border: 1px solid rgb(19, 19, 94); */
}

footer nav ul {
	/* align-items: center;
  flex-flow: row wrap; */
	margin: 0;
	padding: 0;
	width: 100%;
	height: auto;
}

footer nav ul li {
	display: inline;
	height: auto;
	margin: 0 2%;
	padding: 0;
	list-style: none;
	font-weight: 900;
	font-stretch: extra-condensed;
}

footer nav ul li:first-child {
	margin-left: 0;
}

footer nav ul li:last-child {
	margin-right: 0;
}

@media screen and (max-width: 1080px) {
	footer {
		width: calc(100% - 30px);
		margin-left: 15px;
		margin-right: 15px;
		margin-bottom: 0;
	}

	footer .left {
		width: 210px;
		min-width: 210px;
		max-width: 210px;
	}

	footer nav {
		flex: 1 0 100%;
		width: auto;
		margin: 0 0 25px;
		margin-right: auto;
	}

	footer nav ul {
		width: fit-content;
	}

	footer nav ul li {
		display: block;
		margin: 0;
	}
}

/* ------------------------------------------------------------------------------------------------ */
/* ------- This is the footer's right section  w/ Benvensite logos + authentication images -------- */
/* ------------------------------------------------------------------------------------------------ */

footer .right {
	margin: 0 0 0 auto;
	width: fit-content;
	max-width: fit-content;
	min-width: fit-content;
	align-self: stretch;
	height: auto;
}

div.allRightsReserved {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-end;
	height: 100%;
	width: 100%;
}

div.allRightsReserved p {
	margin-bottom: 25px; /*Matching Nav Left */
	width: 100%;
	font-size: 1rem;
	font-weight: 100;
	letter-spacing: 0.2rem;
	line-height: normal;
	text-align: right;
}

@media screen and (max-width: 700px) {
	footer {
		flex-wrap: wrap;
	}

	footer .right {
		margin: 0;
		flex: 1 0 100%;
		min-width: fit-content;
		max-width: 100%;
	}

	div.allRightsReserved p {
		text-align: left;
	}
}

/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ----------------------------------------- ADMIN PAGE ------------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */

.googleLogIn {
	display: flex;
	justify-content: center;
	margin-top: 10vh;
	height: 200%;
	width: 100vw;
}

button.googleLogInButton {
	margin: 10vw 0;
	width: 25%;
	height: 25%;
}

#AdminOptions,
#UploadNewArtist,
#ChangeHomepageContent,
#ChangeOnDisplayContent {
	margin: 0 auto;
	padding: 3vw 0;
	width: 75%;
	border-bottom: 1px solid black;
	border-top: 1px solid black;
	min-height: fit-content;
}

#AdminOptions {
	margin-top: 150px;
}

input {
	padding: 0 10px;
	width: 100%;
	max-width: 100%;
	height: 35px;
	white-space: pre-wrap;
	text-align: start;
	box-sizing: border-box;
	font-size: 16px;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-bottom: 10px;
}

input.checkbox {
	margin: 50px 0;
	width: 30px;
	height: 30px;
}

label,
label em {
	width: 100%;
	display: block;
	margin: 10px 0;
	font-size: 1.25rem;
	font-weight: 100;
	letter-spacing: normal;
	line-height: normal;
}

label em {
	font-style: italic;
	font-size: 1rem;
}

textArea {
	max-width: 100%;
	min-width: 100%;
}

.descriptionPage {
	height: 300px;
}
.descriptionBrief {
	height: 100px;
}

button {
	min-width: fit-content;
	padding: 5px;
	margin: 5px;
}

p.successMessage {
	font-style: italic;
	font-weight: 900;
	font-size: 0.9rem;
}

/* styles.css or PrivacyPage.css */

.privacy-page {
	margin: calc(5vw - 2vh + 100px) 2.7vw 0 2.8vw;
	padding: 0;
	width: calc(100% - 2.8vw - 2.7vw - 2.75px);
	height: fit-content;
}

@media only screen and (max-width: 999px) {
	.privacy-page {
		width: calc(100% - 30px);
		margin-left: 15px;
		margin-right: 0;
	}
}

.privacy-page h1 {
	text-align: start;
	margin-bottom: 20px;
}

.privacy-page article:first-of-type {
	border-top: 1px solid black;
}

.privacy-page article {
	margin: 5vh 0 0;
	padding-bottom: 5vh;
	width: 100%;
	max-width: 1300px;
	border-bottom: 1px solid black;
}

.privacy-page article p {
	margin-left: 5%;
	font-size: 2vh;
	text-align: justify;
	letter-spacing: 1px;
	font-weight: 200;
	font-stretch: condensed;
	opacity: 1;
}

.privacy-page article h2 {
	font-size: 2.5vh;
	text-decoration: underline;
}

.privacy-page article h3 {
	font-size: 2.25vh;
}

.privacy-page article h2,
.privacy-page article h3 {
	margin: 2vh 0;
	width: fit-content;
	text-align: justify;
	letter-spacing: 1px;
	font-stretch: condensed;
	opacity: 1;
}

.privacy-section,
.cookies-section {
	margin-bottom: 30px;
}

.popup-banner {
	position: fixed;
	bottom: 0;
	left: 0;
	opacity: 1;
	z-index: 100000;
	width: 100%;
	max-height: 75px;
	height: fit-content;
	min-height: fit-content;
	background-color: white;
	display: flex;
	align-items: center;
	box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
	transition:
		bottom 2s ease-in,
		opacity 0.5s linear; /* animation configuration */
}

/* Add this class for the hidden state */
.popup-banner-hidden {
	/* display: none; */
	opacity: 0;
	bottom: -200px; /* Adjust this value to ensure the banner is completely hidden */
}

.popup-banner a.learnMorePPC {
	margin: 0 0 0 auto;
	border: 1px solid #505962;
	padding: 7.5px 5px 7.5px 9px; /* accounts for letter spacing*/
	min-width: fit-content;
	letter-spacing: 2.75px;
}

.popup-banner p {
	margin: 2.7vw;
	font-size: 1rem;
	font-stretch: 62.5%;
	letter-spacing: 2.75px;
	text-align: justify;
}

.close-popup-btn {
	margin: 0 2.7vw 0 5px;
	background-color: #505962;
	border: 1px solid #505962;
	color: white;
	cursor: pointer;
	letter-spacing: 2.75px;
	padding: 7.5px 5px 7.5px 9px; /* accounts for letter spacing*/
	box-shadow: none;
	width: auto;
	height: auto;
}

@media screen and (max-width: 1000px) {
	.close-popup-btn {
		margin: 0 15px 0 5px;
	}

	.popup-banner p {
		margin-left: 15px;
	}
}

@media screen and (max-width: 670px) {
	.popup-banner {
		max-height: fit-content;
	}
}

@media screen and (max-width: 525px) {
	.popup-banner p {
		width: 90%;
	}

	.popup-banner {
		display: flex;
		flex-wrap: wrap;
		/* justify-content: center; */
		padding: 10px 0 20px;
	}

	.popup-banner a.learnMorePPC {
		margin-left: 15px;
	}
}

.subscribePopUpOverlay {
	position: fixed;
	z-index: 100000;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	background-image: radial-gradient(
		rgba(255, 255, 255, 0.8),
		rgba(255, 255, 255, 0.4)
	);
}

.subscribePopUpBox {
	width: 30%;
	min-width: 280px;
	max-height: fit-content;
	padding: 2.5%;
	border-radius: 0px;
	background-color: white;
	border: 2px ridge rgb(240, 240, 240);
	/* box-shadow: 1px 1px black; */
}

p.subLink {
	font-family: "ITC Franklin Gothic Std";
	color: black;
	opacity: 1;
	margin: 0;
	padding: 0;
	letter-spacing: 4px;
	font-weight: normal;
	font-stretch: extra-condensed;
	text-decoration: none;
	cursor: pointer;
	font-size: 1rem;
	max-width: fit-content;
	display: inline;
}

.subscribePopUpBox p,
.subscribePopUpBox .buttonBox {
	width: auto;
	text-align: center;
	/* max-width: fit-content; */
	margin: 0 auto;
	color: #505962;
}

.subscribePopUpBox input {
	width: 100%;
	border-radius: 20px;
}

.subscribePopUpBox button {
	/* margin: 0; */
	display: inline;
	background-color: rgb(250, 250, 250);
	padding: 5px 10px;
	border: 1px solid white;
	/* transition: opacity 1ms linear 0; */
	color: #505962;
	transition: background-color 500ms linear 0ms;
}

.subscribePopUpBox button:hover {
	cursor: pointer;
	background-color: rgb(237, 237, 237);
	transition: background-color 500ms linear 0ms;
}

.subscribePopUpBox button:first-child {
	margin-right: 15px;
}

p.alertMessage {
	font-style: italic;
	font-size: 0.8rem;
}

p.errorMessage {
	color: red;
	font-style: italic;
	font-size: 0.8rem;
}

@media screen and (max-width: 500px) {
	.subscribePopUpBox {
		margin: 0 15px;
		min-width: 200px;
		width: 80%;
		max-width: 90vh;
		/* max-height: 90vh; */
		min-height: fit-content;
	}

	.subscribePopUpBox button:first-child {
		margin-right: 0;
	}
}
